import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import TeamIllustrationSrc from "images/team-illustration-2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import CardWithThreeRows from "components/cards/CardWithTopIcon.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between w-full mx-auto xs:py-20 md:py-10`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const CardColumn = tw(Column)`md:w-8/12 flex-shrink-0 lg:relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-0 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-0 lg:ml-0 md:order-last`
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left md:h-128 md:justify-center md:flex md:flex-col`;

const Subheading = tw(SubheadingBase)`text-center md:text-left pr-6`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Actions = styled.div`
  ${tw`relative max-w-md mx-auto lg:mx-0 mt-10 xs:justify-center`}
  button {
    ${tw`xs:m-auto sm:m-0 w-3/4 bg-secondary-600 text-gray-100 mr-2 my-4 sm:my-2 py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-secondary-900 transition duration-300`}
  }
`;


export default ({
  subheading = "",
  heading = (
    <>
      Designed & Developed by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  buttonUrl="https://wa.me/918826622806/?text=Hi, How can i GROW my Digital Content Business with Aurum",
  buttonText="Request Demo",
  imageSrc = TeamIllustrationSrc,
  imageRounded = true,
  imageBorder = false,
  imageShadow = false,
  imageDecoratorBlob = false,
  textOnLeft = false,
  steps = null,
  decoratorBlobCss = null,
  noCardSpace= true,
  cards=[],
  isAnimate = true
}) => {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>{heading}</Heading>
            <Subheading>{subheading}</Subheading>
            <Actions>
              <a href={buttonUrl}><button className="bt-round">{buttonText}</button></a>
            </Actions>
          </TextContent>
        </TextColumn>
        <CardColumn>
          {cards.length > 0  && (<CardWithThreeRows noCardSpace={noCardSpace} cards={cards} isAnimate={isAnimate} />)}
        </CardColumn>
      </TwoColumn>
    </Container>
  );
};
