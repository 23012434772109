import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Lpimage from "../../images/clientLogos/languagePantheon.png";
import Gradeup from "../../images/clientLogos/gradeup.png";
import Iamsnext from "../../images/clientLogos/iamsnext.svg";
import Uniqueshiksha from "../../images/clientLogos/uniqueshiksha.png";
import DBMI from "../../images/clientLogos/dbmi.png";
import Igp from "../../images/clientLogos/igp.png";
import Apollo from "../../images/clientLogos/apollohospitals.png";
import Govt from "../../images/clientLogos/indianGovt.png";
import PJ from "../../images/clientLogos/PJ.png";
import Bajajfinserv from "../../images/clientLogos/Bajaj-Finserv.png";
import GEMLOGO from "../../images/clientLogos/gem-logo.png";
import Housing from "../../images/clientLogos/housing.png";
import Reso from "../../images/clientLogos/resonance3.png";
// import Igiaviation from "../../images/clientLogos/igiaviationdelhi.png"

const Container = styled.ul`
  ${tw`relative`}
  li {
    ${tw`xs:w-2/5 lg:!w-[133px]`}
  }
`;

export default ({
  clientLogos = [
    {
      logoSrc: Igp,
      clientName: "IGP Institute",
      increaseSize: false,
    },
    {
      logoSrc: Govt,
      clientName: "Govt of India",
      increaseSize: false,
    },
    {
      logoSrc: GEMLOGO,
      clientName: "DBMgemI",
      increaseSize: true,
    },

    // {
    // 	logoSrc: Reso,
    // 	clientName: "Resonance",
    // 	increaseSize:false
    // },

    {
      logoSrc: Bajajfinserv,
      clientName: "Bajaj Bajaj-Finserv",
      increaseSize: false,
    },
    {
      logoSrc: Apollo,
      clientName: "Apollo Hospital",
      increaseSize: false,
    },
    {
      logoSrc: Lpimage,
      clientName: "Language Pantheon",
      increaseSize: false,
    },
    {
      logoSrc: PJ,
      clientName: "Parveen Jindal",
      increaseSize: false,
    },

    // {
    // 	logoSrc: Uniqueshiksha,
    // 	clientName: "Unique Shiksha",
    // 	increaseSize:false
    // },
    {
      logoSrc: DBMI,
      clientName: "DBMI",
      increaseSize: true,
    },

    // {
    //   logoSrc: Gradeup,
    //   clientName: "Gradeup",
    //   increaseSize: false,
    // },
    // {
    // 	logoSrc: Iamsnext,
    // 	clientName: "IAMS"
    // },
  ],
}) => {
  return (
    <Container className="wr-clients">
      {clientLogos.map((item, index) => (
        <li>
          <img
            src={item.logoSrc}
            alt={item.clientName}
            style={
              item.increaseSize == true ? { height: "auto" } : { width: "auto" }
            }
          />
        </li>
      ))}
    </Container>
  );
};
