import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { components } from "ComponentRenderer.js";
import AniImage from "../../images/mediaLogos/ani.jpeg";
import Edtimes from "../../images/mediaLogos/edtimes.png";
import Midday from "../../images/mediaLogos/midday.png";
import Dailyhunt from "../../images/mediaLogos/dailyhunt.png";
import Lokmattimes from "../../images/mediaLogos/lokmat.jpeg";
import Businesstandard from "../../images/mediaLogos/bs.png";
import IndianCommunity from "../../images/mediaLogos/ic.png";
// import TheIndianCommunity from "../../images/mediaLogos/indiancommunity";
import { Helmet } from "react-helmet";
// import Igiaviation from "../../images/mediaLogos/igiaviationdelhi.png"

const Container = styled.ul`
  ${tw`relative py-10`}
  img {
    ${tw`xs:h-full pr-10 `}
  }
`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const ColumnHeading = tw.h2`xs:text-center w-full text-black pt-5`;
const MediaWrapper = tw.div`flex lg:flex-wrap md:flex-wrap justify-center lg:flex-row md:flex-row my-8 xs:mb-5 xs:flex-col xs:items-center xs:pl-10 lg:w-3/5 md:w-full w-full mx-auto`;
const Link = tw.a``;
export default ({
  mediaLogos = [
    {
      logoSrc: AniImage,
      mediaName: "AniImage",
      mediaLink:
        "https://www.aninews.in/news/business/aurum-labs-revolutionizes-online-video-learning-with-offline-encrypted-videos-gd-pd-classes-for-ca-institutes-at-affordable-rates20240513181732/",
    },
    {
      logoSrc: Lokmattimes,
      mediaName: "Lokmat",
      mediaLink:
        "https://www.lokmattimes.com/business/aurum-labs-revolutionizes-online-video-learning-with-offline-encrypted-videos-gd-pd-classes-for-ca-institutes-at-affordable-rates/",
    },
    {
      logoSrc: Businesstandard,
      mediaName: "BusinessStandard",
      mediaLink:
        "https://www.business-standard.com/content/press-releases-ani/aurum-labs-revolutionizes-online-video-learning-with-offline-encrypted-videos-gd-pd-classes-for-ca-institutes-at-affordable-rates-124051301115_1.html",
    },

    {
      logoSrc: Midday,
      mediaName: "midday",
      mediaLink:
        "https://www.mid-day.com/brand-media/article/aurum-labs-enabling-national-brands-achieve-inevitable-success-in-online-education-business-23223564",
    },

    {
      logoSrc: Dailyhunt,
      mediaName: "dailyhunt",
      mediaLink:
        "https://m.dailyhunt.in/news/india/english/anienglish-epaper-anieng/aurum+labs+revolutionizes+online+video+learning+with+offline+encrypted+videos+gd+pd+classes+for+ca+institutes+at+affordable+rates-newsid-n608304780?listname=newspaperLanding&topic=business&index=1&topicIndex=3&mode=pwa&action=click",
    },

    {
      logoSrc: Edtimes,
      mediaName: "edtimes",
      mediaLink:
        "https://edtimes.in/aurum-labs-enabling-national-brands-achieve-inevitable-success-in-online-education-business/",
    },

    {
      logoSrc: IndianCommunity,
      mediaName: "IndianCommunity",
      mediaLink:
        "https://www.theindiancommunity.org/news/aurum-labs-%E0%A4%B8%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%80%E0%A4%A6%E0%A4%B0%E0%A5%87%E0%A4%B7%E0%A5%81-offline-encrypted-videos-ca-institutes-%E0%A4%95%E0%A5%83%E0%A4%A4%E0%A5%87-gd-pd-classes-%E0%A4%87%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%A8%E0%A5%87%E0%A4%A8-%E0%A4%B8%E0%A4%B9-online-video-learning-%E0%A4%87%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%A4%E0%A5%8D%E0%A4%B0-%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%A8%E0%A5%8D%E0%A4%A4%E0%A4%BF%E0%A4%82-%E0%A4%95%E0%A4%B0%E0%A5%8B%E0%A4%A4%E0%A4%BF.36890/sa",
    },
    // {
    //   logoSrc: TheIndianCommunity,
    //   mediaName: "IndianCommunity",
    //   mediaLink:
    //     "https://www.theindiancommunity.org/news/aurum-labs-%E0%A4%B8%E0%A4%B8%E0%A5%8D%E0%A4%A4%E0%A5%80%E0%A4%A6%E0%A4%B0%E0%A5%87%E0%A4%B7%E0%A5%81-offline-encrypted-videos-ca-institutes-%E0%A4%95%E0%A5%83%E0%A4%A4%E0%A5%87-gd-pd-classes-%E0%A4%87%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%A8%E0%A5%87%E0%A4%A8-%E0%A4%B8%E0%A4%B9-online-video-learning-%E0%A4%87%E0%A4%A4%E0%A5%8D%E0%A4%AF%E0%A4%A4%E0%A5%8D%E0%A4%B0-%E0%A4%95%E0%A5%8D%E0%A4%B0%E0%A4%BE%E0%A4%A8%E0%A5%8D%E0%A4%A4%E0%A4%BF%E0%A4%82-%E0%A4%95%E0%A4%B0%E0%A5%8B%E0%A4%A4%E0%A4%BF.36890/sa",
    // },
  ],
}) => {
  return (
    <>
      <Helmet
        title="Aurum Labs - Content Delivery and Monetisation"
        meta={[
          {
            name: "description",
            content:
              "Aurum Labs Enabling National Brands Achieve Inevitable Success in Online Education Business",
          },
          {
            property: `og:title`,
            content: "Aurum Labs - Content Delivery and Monetisation",
          },
          {
            property: `og:description`,
            content:
              "Aurum Labs Enabling National Brands Achieve Inevitable Success in Online Education Business",
          },
        ]}
      />
      <Container className="wr-medias">
        <Heading>Aurum In Media</Heading>
        <MediaWrapper>
          {mediaLogos.map((item, index) => (
            <Link href={item.mediaLink} target="_blank">
              <img
                src={item.logoSrc}
                alt={item.mediaName}
                style={{ width: "220px", margin: "10px" }}
              />
            </Link>
          ))}
        </MediaWrapper>
      </Container>
    </>
  );
};
