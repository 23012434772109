import React, {useState} from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import illustration from "images/aurumlogin.png";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import {serverUtilities} from '../services/serverutilities.service';

const Container = tw(ContainerBase)`min-h-screen bg-white text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900  flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto my-12`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-brand-500 text-gray-100 w-full py-4 rounded-lg hover:bg-brand-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-white text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-5 xl:m-5 w-full max-w-full bg-contain bg-center bg-no-repeat`}
`;
const ErrorLabel = tw.p`text-red-700 text-sm mt-5`;

// const [inputField, setInputField] = useStates(null)
export default ()=>{
  let logoLinkUrl = "/";
  let illustrationImageSrc = illustration;
  let headingText = "Log In to Your Account";
  let submitButtonText = "Sign In";
  let SubmitButtonIcon = LoginIcon;
  let forgotPasswordUrl = "#";
  let signupUrl = "#";
  let fields = {}
  const [msgVal, setError] = useState('');

  let name = "adminLoginData";
  var date = new Date();
  let value = "expired";
  date.setTime(date.getTime() - (6*60*60*1000));
  let expires = "; expires=" + date.toUTCString();
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";


  const handleChange = (e) => {
    console.log(e.target.name);
    fields[e.target.name] = e.target.value; 
  }
  const sendLogin = (e) => {
    console.log(fields);
    e.preventDefault();
    console.log("I am clicked");
    fields['username'] = document.getElementById('username').value;
    fields['password'] = document.getElementById('password').value;
    if(fields.username == ''){
      setError("Please Enter Email Id")
      return;
    }
    if(fields.password == ''){
      setError("Please Enter Password");
      return;
    }
    console.log(fields.username);
    serverUtilities.login('email',fields.username,fields.password).then(loginData=>{
      console.log(loginData);
      if(loginData.status == '0'){

        setError(loginData.message);
        
      }
      else
      {
        let value = loginData.cookieData;
        let name = "adminLoginData";
        var date = new Date();
        date.setTime(date.getTime() + (6*60*60*1000));
        let expires = "; expires=" + date.toUTCString();
        if (loginData.usertype == 'user')
          name = "userLoginData";
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        window.location.href = loginData.url; //:w"https://aurumlabs.co/onlinelearning/admin/login";
      }
    });
  }

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} className="aurum-logo" style={{width:'auto'}} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              {msgVal !='' && (<ErrorLabel>{msgVal}</ErrorLabel>)}
              <FormContainer>
                <Form>
                  <Input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required placeholder="Email" id="username" name="username" onChange={handleChange} />
                  <Input type="password" required id="password" placeholder="password" name="password" onChange={handleChange}  />
                  <SubmitButton type="submit" onClick={sendLogin} >
                    <SubmitButtonIcon className="icon" />
                    <span className="text">{submitButtonText}</span>
                  </SubmitButton>
                </Form>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  <a href="https://wa.me/918826622806/?text=Hi, I Forgot My Admin Password. Pls Assist" tw="border-b border-gray-500 border-dotted">
                    Forgot Password ?
                  </a>
                </p>
                
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  )
}

