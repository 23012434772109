import React, { useEffect, useLayoutEffect, useRef } from "react";

import tw from "twin.macro";
import { useLocation } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/HpContent.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import Workstations from "images/workstations.jpeg";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
import ServersImage from "images/server.jpg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import PrinterImage from "images/printers.jpeg";
import SwitchesImage from "images/switches.jpeg";
import caseStudyProfileImage from "images/tsi_deepak_tek.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Dealership from "images/dealership.jpeg";
import { components } from "ComponentRenderer.js";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from "react-helmet";

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
}) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;

  const tawkToPropertyId = "616306cf157d100a41abb1ea";

  const tawkToKey = "1fhld5795";

  useEffect(() => {
    console.log("hiiiiiii");
    tawkTo(tawkToPropertyId, tawkToKey);
  }, []);
  const location = useLocation();
  const desktopsRef = useRef(null);
  const elements = location.hash.substring(1);
  useEffect(() => {
    console.log("iiiiiiiii", desktopsRef);
    if (location.hash === elements && desktopsRef.current) {
      desktopsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);
  return (
    <>
      <Helmet
        title="Aurum Labs - HP Commercial Series Partner, Authorised on GeM"
        meta={[
          {
            name: "description",
            content: "HP Commercial Series Laptops, Printers, Network Switched",
          },
          {
            property: `og:title`,
            content:
              "Aurum Labs - HP Commercial Series Partner, Authorised on GeM",
          },
          {
            property: `og:description`,
            content: "HP Commercial Series Laptops, Printers, Network Switched",
          },
        ]}
      />
      <Hero
        roundedHeaderButton={true}
        mainTitle="Authorised HP Partner for Companies & GeM"
        subTitle="Laptops, Desktops, Workstations, Printers, Photocopiers, Servers & Network Switches."
        buttonText="Lets Talk"
        imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/hp-website.png"
        showClient={true}
      />
      <AnimationRevealPage>
        {/* <Features
          subheading={
            <Subheading>
              Content Platform that makes Planning, Delivery and Engagement
              Easier than Ever
            </Subheading>
          }
          heading={<>One Platform, Endless Potential</>}
        /> */}
        <VerticalSeprator className="v-seperator" />

        <MainFeature
          primaryButtonText="Explore More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, I am looking for HP Solutions for Notebooks, Desktops and Workstations"
          }
          heading="Notebooks, Desktops & Workstations"
          description="HP desktops and workstations offer reliable performance for various user needs.<br/><br /> Consumer desktops cater to home users and students, while business desktops provide security and manageability for enterprises.<br/><br/>Workstations, ranging from entry-level to high-end, are designed for demanding tasks like CAD/CAM and content creation.<br/><br/>With features like powerful hardware, reliability, security, and customization options, HP ensures a seamless user experience for personal, business, and professional use. <p><b>Aurum: Your trusted authorized dealer for HP desktops and workstations.<b/></p>"
          subheading="Nothing is built for business like HP"
          textOnLeft={false}
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/hpwork2.jpg"
          imageBorder={false}
          imageDecoratorBlob={false}
          id="desktops"
          ref={desktopsRef}
        />
        <MainFeature
          id="servers"
          ref={desktopsRef}
          primaryButtonText="Explore More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, I am looking for HP Solutions for Servers and Storage"
          }
          heading="HP Server Solutions: Powering Your Business"
          description="<p><b>Unmatched Reliability:</b> HP servers are renowned for their reliability and security, keeping your business running smoothly with minimal downtime. This translates to maximized productivity and peace of mind.</p><p><b>Scalability and Flexibility:</b> HP servers are designed to grow with your business.  They can be easily scaled up or down to meet your changing needs, ensuring you have the right power for the job.</p>

          <p><b>Enhanced Security:</b> HP servers come equipped with advanced built-in security features to safeguard your valuable data and applications.</p>

          <p><b>Improved Performance:</b>  Experience industry-leading performance from HP servers. They are built to handle even the most demanding workloads efficiently.</p>

          <p><b>Reduced Costs:</b> HP servers can help you save money in the long run.  Their energy efficiency and lower total cost of ownership (TCO) contribute to significant savings.</p>

          <p><b>Wide Range of Options:</b> HP offers a diverse range of servers to suit your specific needs and budget. From tower servers for small offices to rack servers for large data centers, and even microservers for compact solutions, there's an HP server perfect for you.</p>

          <p><b>Expert Support:</b> HP's expert support services are available to ensure the smooth operation of your servers.  They can provide quick resolution to any issues that may arise, keeping your business up and running.</p><p><b>Call today</b> for Customised Solutions with HP server families like ProLiant, HPE Synergy and Apollo, SAN Storages and Related Softwares."
          subheading="Unleash Peak Performance: HP Server Solutions for Every Business Need"
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/servers.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          id="printers"
          ref={desktopsRef}
          primaryButtonText="Learn More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, I am looking for HP Solutions for Printers Scanners and Photocopiers"
          }
          heading="HP Printers & Photocopiers: Powering Your Productivity"
          description="<p><b>Reliability:</b> HP printers and photocopiers are built to deliver consistent performance, ensuring reliable operation for businesses of all sizes.</p><p><b>Quality:</b> With advanced printing technologies and high-quality components, HP devices produce professional-quality prints and copies with crisp text and vibrant colors.</p><p><b>Innovation:</b> HP leads the industry in printer and copier innovation, offering features such as wireless connectivity, mobile printing, and cloud integration for enhanced productivity and convenience.</p><p><b>Versatility:</b> HP printers and copiers come in a variety of models and configurations to meet diverse printing needs, from small office printers to high-volume production devices.</p><p><b>Efficiency:  </b> HP devices are designed for efficiency, with features like automatic duplex printing, energy-saving modes, and high-capacity paper trays to streamline workflows and reduce waste.</p><p><b>Security:</b> HP prioritizes security in its printing solutions, offering features such as secure printing, user authentication, and firmware updates to protect against data breaches and unauthorized access.</p><p><b>Support:</b> HP provides comprehensive support services, including warranty coverage, technical assistance, and driver updates, ensuring a seamless printing experience for customers.</p>"
          subheading="Boost Efficiency with HP: Printers & Photocopiers for Every Need"
          textOnLeft={false}
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/printers.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          id="switches"
          ref={desktopsRef}
          primaryButtonText="Lets Connect"
          primaryButtonUrl="{https://wa.me/918920403902?text=Hi, I am looking for HP Solutions for Network Switches}"
          heading="HP Networking Switches: Connecting Your Business Securely"
          description="<p><b>Enhanced Performance:</b> Emphasize how HP switches deliver high-speed connectivity, optimizing network performance and data transfer rates.</p><p><b>Improved Reliability:</b> Highlight HP's reputation for reliable and stable network connections, minimizing downtime and disruptions.</p><p><b>Scalability and Flexibility:</b> Focus on the ability to scale HP switches up or down as your network grows, ensuring it can adapt to changing needs.</p><p><b>Advanced Security Features:</b> Showcase HP's built-in security features that protect your network from unauthorized access and cyber threats.</p><p><b>Simplified Management:</b> Mention HP's user-friendly management tools for easy configuration, monitoring, and troubleshooting of your network switches.</p><p><b>Wide Range of Options:</b> Outline the diverse range of HP network switches available, catering to different network sizes and functionalities (e.g., unmanaged switches, managed switches, PoE switches).</p><p><b>Energy Efficiency:</b> Highlight HP's energy-efficient switches that can help you reduce power consumption and operating costs.</p><p><b>Call Today</b> to help you choose the the right HP network switch solution from HP Switch Family Like Aruba Instant On, ArubaOS, and ProCurve</p>"
          subheading="Build a Robust Network with HP Networking Switches"
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/netswitches.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />

        <PreFooter
          fromPage="HP Page"
          formheading="IT Solutions & Execution that Suits You, Ampliflies Your Business & Growth"
          formsubheading=""
          heightlightText="Lets Connect to Discuss the Best HP Solution for Your Needs"
        />
      </AnimationRevealPage>
      <Footer />
    </>
  );
};
