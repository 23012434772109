import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import UiUxImage from "images/aurum_Icons/uxcx-engagement.svg";
import agilityImage from "images/aurum_Icons/agilityImage.svg";
import unifiedLibrary from "images/aurum_Icons/unifiedLibrary.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

const Container = tw.section`relative bg-baseGray w-full pt-20 pb-10`;

const ThreeColumnContainer = styled.div`
  ${tw`xs:px-10 sm:px-0 flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-0 md:py-10`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-textBlack text-base`;
const Heading = tw.h2`xs:text-center w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;
const HighlightedText = tw.span`text-primary-500`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`text-center sm:text-left h-full mx-2 px-0 py-2`}
  .imageContainer {
    ${tw`text-center p-4 h-48`}
    img {
      ${tw`w-40 mx-auto`}
    }
  }

  .textContainer {
    ${tw`xs:ml-0 sm:ml-5 mt-4 sm:mt-2 text-left `}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-lg leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-400 text-base leading-loose`}
  }
  .description p {
    ${tw`mt-5`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "",
  subheading = "",
  description = "",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: agilityImage,
      title: "Agile, Secure, Robust - Hassle Free Software Solution",
      description:
        "Limitless Flexibility, Customer Centric Framework, Development, Maintenance & Rock Solid Reliability for Business Critical Applications. <p>On site, off-site IT Teams for custom Applications as per Govt. Norms</p> <p><b>Grow with Ease.</b></p>",
    },
    {
      imageSrc: UiUxImage,
      title: "Video Conference Halls / Live Studios",
      description:
        "Experience Cutting Edge Conference Halls, Live Studio Setups along with Secure & Encrypted Live Streaming Solutions Tailored to Your Needs. <p>High Quality Setups for Seamless Communication & Content Creation.</p><p> <b>Transforming Ideas Into Reality.</b></p>",
    },

    {
      imageSrc: unifiedLibrary,
      title: "IT Infrastructure Solutions & Maintenance Services",
      description:
        "Future-Ready, Robust, and Intelligent Solutions for Data Centers, Servers, Networking, and More. <p>Partnered with leading industry brands such as HP, Dell, Microsoft, Intel, and others</p> <p><b>Make the Most out of It.</b></p>",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <div>
        <Heading>{heading}</Heading>
        {subheading && <Subheading>{subheading}</Subheading>}
        {description && <Description>{description}</Description>}
      </div>
      <ThreeColumnContainer>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <div className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </div>
              <div className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: card.description }}
                ></div>
              </div>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
