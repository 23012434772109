import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/AurumHeroContent.js";
import FeaturesLive from "components/features/LiveThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/clientLogos/igp.png";
import caseStudyProfileImage from "images/lp_anuj_k_acharya.png"
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import DesignIllustration from "images/aurum_Icons/heroImg.svg";
import { components } from "ComponentRenderer.js";
import { Helmet } from 'react-helmet';
import UiUxImage from "images/aurum_Icons/uxcx-engagement.svg";
import agilityImage from "images/aurum_Icons/agilityImage.svg";
import unifiedLibrary from "images/aurum_Icons/unifiedLibrary.svg";
export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  }) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;

  return (
    <>
      <Helmet
      
      title='Offline Encrypted Videos GD-PD | Offline Videos'
      
      meta={[
        {
          name: 'description',
          content: 'Offline Encrypted Videos GD-PD, for Large Audiences and Encryption',
        },
        {
          property: `og:title`,
          content: 'VOffline Encrypted Videos GD-PD| Recorded Videos',
        },
        {
          property: `og:description`,
          content: 'Offline Encrypted Videos GD-PD, for Large Audiences and Encryption',
        }]}
    />
    
      <Hero roundedHeaderButton={true} 
        mainTitle="Powerful All in One Offline Video Streaming Solution" 
        subTitle="Enhance Your Offline Video Course with LIVE Sessions, Updates, Recorded Videos, PDFs, and Tests in a Single Platform."
        buttonText="Lets Connect"
        buttonUrl="https://wa.me/918920403902/?text=Hi, We would like to use AURUM Offline videos platform..."
        imageSrc="https://ik.imagekit.io/zjste1wkulv/tr:q-90/content_delivery_home_new.gif"
        showClient= {true}
      />
      <AnimationRevealPage>
        
       
       
        <FeaturesLive
          subheading={<Subheading>Platform that makes Encryption, Delivery and Monetisation Easier than Ever</Subheading>}

          heading={
            <>
              Offline Videos Streaming Solution
            </>
          }
          cards={
            [
              { 
                imageSrc: agilityImage, 
                title: "Automatic Encryption and Distribution",
                description: "Server Side Video Encryptions and Distributions of Live Videos." 
              },

              {
                imageSrc: UiUxImage,
                title: "Engaged & Delighted Learners",
                description: "<p>Live Sessions, PDFs, Tests and more in a Single Curated Course</p><p> <b></b></p>"
              },
              { imageSrc: unifiedLibrary, 
                title: "Sell Courses on Our Market Place",
                description: "Bundle Your Offerings on Our Platform and Grow Business Easily." 
              }
            ]
          }
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918920403902?text=Hi, We would like to live stream videos with Aurum"
          heading="The Most Powerful and Flexible Offline Video Delivery (GD-PD)"
          description="Deliver Delightful Course Experiences to Learners of any scale around the globe. Aurum takes care of the entire encryption, streaming and distribution process, enabling you to focus only on the Content.<p><b>Fully Encrypted Videos / PDF Files</b><br />Enterprise grade security with dynamic AES encryption. Institutions have full dynamic control of their assets with Device Lock, View Counts and Blocked Screen Capture in Mobiles and PCs**. Aurum Offers Automatic Encryption and Disctribution of Live Sessions without any hassles.</p><p><b>Add Live / Recorded Videos, MCQs & PDF in Offline Course</b><br />Aurum is built to offer integrated courses to students. Deliver best learning experiences with a Mix of Live Sessions with Offline Videos, Adding new Recorded Videos on the Go, PDFs and Regular Tests (MCQ). Aurum Platofrm offers Buffer free live streaming, secured video hosting and continuous playback on almost all devices.</p><p><b>Usage Reports and Easy Key Management</b><br />Easily Manage and Deliver Course Access to students with Complete Control and Easy Key / Code Management in Aurum Platform.</p>"
          subheading="One Platform, End-to-End Video Solutions"
          textOnLeft={false}
          imageSrc="https://aurumfiles.b-cdn.net/video-streaming-recording.png"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918920403902?text=Hi, We would like to live stream videos with Aurum"
          heading="Benefits of Using Aurum Platform"
          description="<p>You get more time to focus on business development and Build Brand Identity. Get More Customisation and Flexibility, Easily Manage Video Access Control and less technical management stress.</p><p><b>Stream Videos with White Labelling</b><br />Embed with Aurum Player, API or deploy ready to use Aurum Platform at a sub domain of your company. Establish your brand identity as per your use case.</p><p><b>Easily Manage User Level Access & DRM</b><br />Define user device, view counts, encryption & validity, modify with ease. Get in full control of digital rights for your assets, monetise efficiently</p><p><b>Expand your Audience with Online / Offline Videos</b><br />Enable users to access videos via internet or in downloaded mode with internet. Use Single File Source for Both formats in mobiles and desktops. Deliver best experiences for your video content.</p>"
          subheading="Your private video library like Youtube"
          imageSrc="https://aurumfiles.b-cdn.net/video-streaming-on-brand-live.png"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, We would like to live stream videos with Aurum"
          heading="Live Stream on Apps & Devices that Users Love"
          description="Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.<p>Deliver Live Sessions on Laptops with Complete Content <b>Security from Downloads and Screen Capture**</b>, enabling much more user engagement and satisfaction in long duration sessions.</p>"
          subheading="Optimise Your User Experience to the Fullest"
          textOnLeft={false}
          imageSrc={DevicesImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          primaryButtonText="Let's Connect"
          primaryButtonUrl = "https://wa.me/918920403902/?text=Hi, We would like to live stream videos with Aurum"
          heading="Why Aurum is the Best Offline Video Platform for Me ??"
          description="<p>Aurum Software offers robust infrastructure and auto Encryption capabilities, secured and easy Live Video integration and a Market Place for CA Courses.</p><p>Choose Aurum if You …<ul><li>Need One Stop Solution for Offline and Live Courses.</li><li>Need to Ingest videos from Multiple Sources. Like upload local recording, Live Videos, Videos from Youtube etc.</li><li>Need Content Security without any Compromise.</li><li>Need to deliver content dynamically and flexibly - Online and Offline, with Live, Recorded, Offline Videos, PDFs and MCQs.</li><li>Need a cloud based solution with pay-as-you go pricing rather than monthly commitments.</li><li>Don’t want to invest huge and manage softwares.</li>"
          subheading=""
          imageSrc={ContentSecurityImage}
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <FAQ
          subheading={<Subheading></Subheading>}
          description={'The Best Platform for Offline Videos - GD-PD'}
          heading={
            <>
              Frequently Asked Questions
            </>
          }
          faqs={[
            {
              question: "I am already using another Software, how can i migrate easily ??",
              answer:"<p>Our Support Team will guide you through the process. The Best solution though is always to shift on new software when a new batch is annoounced. For some time, both softwares will be used till migration is complete.<p><br /><p>We can ensure you will not have any cost overhead.</p>"
            },
            {
              question: "What is Automatic Encryption and Disctribution ?",
              answer:
                "Any institute usually conduct live classes, and then these classes are encrypted manually, uploaded on google drive, and includes many other tasks till it is shared with the student. If you Live Stream with Aurum, we automatically encrypt videos for mobile and laptops, and add their download links for students in content listings.<br /><br />A lot of things happen behind the scenes, but you need not worry about them. Let Aurum enable you to focus only on your business and content."
            },
            {
              question: "How does the Pricing Works ?",
              answer:
                "Aurum Pricing is always Pay as You Go, with all pricing based on GB of data stored and GB of data streamed. 5 Hrs of very good quality video usually forms 1 GB of data. Our pricing is based on usage and volume, and hence the best pricing is offered once we have a complete idea about your use case and volume.<br /><br />For Offline Videos, our pricing is based on per activation key / code used without any condition on the GB of data in the content (if encrypted videos are not downloaded from our servers). The pricing is completely based on volume and other usage patterns & requiremens. <br /><br />We suggect to contact our sales team for the best quote today."
            },
            {
              question: "How Can Aurum Help Us Increase Our Sales ?",
              answer:
                "Aurum platforms sells offline GD-PD courses of major CA Institutions through its Market Place \"Artam\" and has a captive audience. Its pretty easy for us to popularise your courses and promote in target students. We sell courses at competitive revenue sharing models. <br /><br />We suggect to contact our team for the best quote today."
            }
          ]}
        />
        <PreFooter fromPage = "VOD Streaming" formheading = "Live Streaming with Offline Courses Amplifies User Engagement and Revenues" formsubheading = "Book Our Free Discussion Session Today - " heightlightText="How reduce Tech Costs and Grow Revenues"/>
      </AnimationRevealPage>
      <Footer />
   </>
  );
}
