import React, { useEffect } from "react";

import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/Edtech.js";
import Features from "components/features/ThreeColWithTopImage.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import WhyChoose from "components/features/TwoColWithThreeCardsLayout.js";
import CaseStudy from "components/testimonials/CaseStudyTextOnly.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/DownloadApp.js";
import PreFooter from "components/footers/PreFooterBrand.js";
import EdTechFooter from "components/footers/EdTechFooter.js";
import StrongBrandImage from "images/aurum_Icons/uiuxImage.svg";
import BroadCastImage from "images/aurum_Icons/broadcast.svg";
import ConferenceImage from "images/aurum_Icons/conference.svg";
import OnlineImage from "images/aurum_Icons/online.svg";
import EbooksImage from "images/aurum_Icons/ebook.svg";
import EventsImage from "images/aurum_Icons/event.svg";
import WebinarsImage from "images/aurum_Icons/web.svg";
import YoutubeImage from "images/aurum_Icons/yt.svg";
import APIsImage from "images/aurum_Icons/api.svg";
import DynamicContentImage from "images/aurum_Icons/dynamic.svg";
import VideoContentImage from "images/aurum_Icons/aurum-video-library.svg";
import ContentSecurityImage from "images/aurum_Icons/aurum-contentSecurity.svg";
import DevicesImage from "images/aurum_Icons/aurum-devicesImage.svg";
import caseStudyLogoImage from "images/tsilogo.png";
import caseStudyProfileImage from "images/tsi_deepak_tek.png";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import EdTechSolutions from "images/edtech.png";
import { components } from "ComponentRenderer.js";
import AmcServices from "images/amc_services.png";
import EdTech from "images/ed-tech.jpg";
import DeliveryContent from "images/del-content.jpg";
import VideoConference from "images/VideoConference.jpg";

// Live Chat plugin Tawkto
import tawkTo from "tawkto-react";

// seo plugin Helmet
import { Helmet } from "react-helmet";

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
}) => {
  const Subheading = tw.p`tracking-widest text-textBlack text-center`;
  const HighlightedText = tw.span`text-primary-500`;
  const VerticalSeprator = tw.div`mt-20 xs:mt-0`;
  const WhyChooseWrap = tw.div`bg-baseGray overflow-hidden`;

  const tawkToPropertyId = "616306cf157d100a41abb1ea";

  const tawkToKey = "1fhld5795";

  useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey);
  }, []);

  return (
    <>
      <Helmet
        title=" Aurum Labs - Intelligent IT Software & Hardware Solutions."
        meta={[
          {
            name: "description",
            content:
              "One Platform for Apps, Video Streaming, Content Delivery & Hardware Solutions",
          },
          {
            property: `og:title`,
            content:
              "Aurum Labs - Intelligent IT Software & Hardware Solutions",
          },
          {
            property: `og:description`,
            content:
              "One Platform for Apps, Video Streaming, Content Delivery & Hardware Solutions",
          },
        ]}
      />
      <Hero
        roundedHeaderButton={true}
        mainTitle="Streamline, Modernise & Customise Your Digital Experiences."
        subTitle="Unmatched Expertise, Capabilities, and Scalable Solutions for All Your Technology Needs"
        buttonText="Lets Talk"
        imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/edtechsolution.jpeg"
        showClient={true}
      />
      <AnimationRevealPage>
        <Features
          subheading={
            <Subheading>
              Solutions that makes Planning, Execution & Delivery Easier than
              Ever.
            </Subheading>
          }
          heading={<>One Platform, Endless Potential</>}
        />
        <VerticalSeprator className="v-seperator" />
        <CaseStudy
          imageSrc={caseStudyLogoImage}
          testimonialText="We recently utilized their services for our studio setup and data center setup in Mumbai, and we couldn't be more pleased with the results. Their team demonstrated exceptional professionalism, technical expertise, and attention to detail. They delivered a state-of-the-art studio and a robust, secure data center that perfectly meets our needs. The project was completed on time and within budget, showcasing their commitment to quality and customer satisfaction. We highly recommend Aurum Labs."
          profileImageSrc={caseStudyProfileImage}
          customerName="Deepak Tekchandani"
          customerTitle="Founder, CEO"
          buttonUrl=""
        />
        <MainFeature
          id="itsupport"
          primaryButtonText="Explore More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, we are looking for ...."
          }
          heading="Agile, Secure, and Robust: Your Hassle-Free Software Solutions"
          description="<p>Our customer-centric framework, ensures seamless adaptation to your unique business needs. Whether you're scaling up or optimizing processes, we tailor our solutions to fit your requirements perfectly.</p><p>We always ensure easy implementation & smooth integrations with your current systems.</p><br /><b>On-Site and Off-Site IT Development Teams</b><p>We offer both on-site and off-site IT teams to develop custom applications in compliance with government norms. Our teams are skilled in creating solutions that adhere to regulatory requirements, ensuring your business always remains compliant.</p>"
          subheading=""
          textOnLeft={false}
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/amc.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          id="conference-studios"
          primaryButtonText="Explore More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, we are looking for AV Conference / Studio Setups"
          }
          heading="Develop Exclusive Conference Halls / Live Studios"
          description="Transform your collaboration and learning experiences with our custom-built audio/video conference halls and live studios. We specialize in crafting exclusive, high-fidelity spaces designed to empower both businesses and educational institutions.<b> Here's how we empower your success:</b><p><b>Seamless Collaboration:</b> Experience crystal-clear audio and high-definition video for flawless communication across teams and continents in your conference hall.</p><p><b>Engaging Learning Environments:</b> Captivate students and foster a dynamic learning experience with our feature-rich live studios, perfect for the education sector.</p><p><b>Unmatched Technology Integration:</b> Benefit from cutting-edge audio/visual systems seamlessly integrated into your space for a smooth and intuitive user experience.</p><p><b>Expert Design & Customization:</b> Our team of specialists works closely with you to tailor the configuration, acoustics, and lighting to your specific needs.</p><br /> Boost productivity in your office with state-of-the-art conference halls.  Revolutionize education with interactive live studios that inspire."
          subheading=""
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/videocon.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          id="hardwaresol"
          primaryButtonText="Explore More"
          primaryButtonUrl={
            "https://wa.me/918920403902?text=Hi, we are looking for AV Conference / Studio Setups"
          }
          heading="Unwavering IT Support: Infrastructure Solutions & Maintenance"
          description="<p>Ensure seamless operations and future-proof your technology with our comprehensive IT infrastructure solutions and maintenance services. We are a GeM-authorized HP Hardware provider, offering unparalleled expertise in:</p><p><b>Data Center Design & Build:</b> Our team of specialists will design, build, and implement your ideal data center solution, ensuring optimal performance, scalability, and security.</p><p><b>Data Center Maintenance:</b> Minimize downtime and maximize efficiency with our proactive maintenance programs, keeping your data center running smoothly 24/7.</p><p><b>Disaster Recovery Site Setup:</b> Be prepared for anything with our expert DR site setup services. We create a robust backup solution to ensure business continuity in the event of disruptions.</p><p><b>HP Hardware Solutions:</b> Leverage our GeM authorization to access a wide range of HP hardware at competitive prices. We'll integrate seamlessly with your existing infrastructure.</p><p>We go beyond just hardware.  We offer a holistic approach to IT infrastructure, providing ongoing support and maintenance to keep your systems running optimally.  Focus on your core business, let us handle the IT foundation.</p>"
          subheading=""
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/itsolutions.jpeg"
          imageBorder={false}
          textOnLeft={false}
          imageDecoratorBlob={false}
        />
        <MainFeature
          id="edtech"
          primaryButtonText="Learn More"
          primaryButtonUrl={innerPages.edtechsolutions.url}
          heading="Deliver Content on Apps & Devices that Users Love, EdTech Solutions Built for Success"
          description="<p>Empower educators and ignite the potential of learners with our comprehensive EdTech solutions.  We are your one-stop solution for creating engaging and effective learning experiences.</p><p>Impress Users with availability of the Content in their choice of devices on <b>Aurum Apps</b> or integrate the content from Aurum Content Library to your existing Web & Apps easily with APIs.<p>Deliver Content Online or Offline (Pen Drive & Google Drive) and enable seamless transitions at any point in their journey. Achieve all this with Ease and Enterprise Grade Security for Videos and PDFs.</p> <p>Deliver Live Sessions on Laptops with Complete Content Security from Downloads and Screen Capture, enabling much more user engagement and satisfaction in long duration sessions.</p>"
          subheading=""
          imageSrc="https://aurumfiles.b-cdn.net/aurumtechimage/delcontent.jpeg"
          imageBorder={false}
          imageDecoratorBlob={false}
        />

        <WhyChooseWrap>
          <WhyChoose
            heading="Ways to use Aurum"
            subheading="Unlimited possibilities and Uniquely great experience every time"
            noCardSpace={true}
            cards={[
              {
                imageSrc: BroadCastImage,
                subText:
                  "Customised Live Studio Solutions from WHF setup to Premium Studios.",
                title: "Studio Setup",
              },
              {
                imageSrc: ConferenceImage,
                subText:
                  "Global Standard AV / Polycom Conference Hall Setup as per Govt Norms.",
                title: "Conference Setups",
              },
              {
                imageSrc: OnlineImage,
                subText:
                  "Live Streaming, Content Libraries and All Sorts of Features to make it a complete experience",
                title: "Online Courses",
              },
              {
                imageSrc: EbooksImage,
                subText:
                  "Complete IT Solution Designing and Execution for Data Centers / DR Sites",
                title: "Data Centers",
              },
              {
                imageSrc: EventsImage,
                subText:
                  "Software Development / Maintenance, On-Site and Off-Site as Per Govt Norms",
                title: "Software Solutions",
              },
              {
                imageSrc: DynamicContentImage,
                subText:
                  "HP Servers, Computers, Printers & Network Switches at Best Prices. (GeM Authorised)",
                title: "HP Products",
              },
              {
                imageSrc: YoutubeImage,
                subText:
                  "Encrypted Live Streaming on All Devices. Low Cost, Global Standards.",
                title: "Live Video Streaming",
              },
              {
                imageSrc: APIsImage,
                subText:
                  "Easy to Use API to build your custom apps for live and recorded video streaming.",
                title: "APIs",
              },

              {
                imageSrc: WebinarsImage,
                subText:
                  "Webinars are one sided, Aurum ups the engagement and interactivity.",
                title: "Webinars",
              },
            ]}
            isAnimate={true}
          />
        </WhyChooseWrap>
        <PreFooter
          fromPage="Home Page"
          formheading="IT Solutions & Execution that Suits You, Ampliflies Your Business & Growth"
          formsubheading=""
          heightlightText="Lets Connect to Discuss"
        />
      </AnimationRevealPage>
      <EdTechFooter />
    </>
  );
};
