import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import StartupLogo from "../../images/fixed-startup-india-logo.png";
import { components } from "ComponentRenderer.js";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import UKASL from "../../images/ukasl.png";
import ISO from "../../images/iso.png";
import EGAC from "../../images/egac.png";
import IAF from "../../images/iaf.png";
import RO from "../../images/ro.png";
import CertificationLogo from "../../images/certificationLogo.png";

const Container = tw.div`relative bg-white -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 lg:flex lg:flex-row justify-between xs:flex xs:flex-col`;

const Column = tw.div`mt-5 md:w-1/6`;
const WideColumn = tw(
  Column
)`text-center md:text-left lg:w-1/4 md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-black`;

const LinkList = tw.ul`mt-4 text-sm font-medium `;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent text-black hocus:text-brand-500 hocus:border-brand-500 pb-1 transition duration-300`;
const LinkB = tw.a`ml-5 border-b-2 border-transparent text-black hocus:text-brand-500 hocus:border-brand-500 pb-1 transition duration-300 first:ml-0 `;
const LinkC = tw.a`border-b-2 border-transparent text-black hocus:text-blackStep-100 hocus:border-blackStep-100 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const AlIngenious = tw.h1`mt-2 text-lg font-bold text-black`;
const LogoImg = tw.img`w-1/2`;
/*const CertifiedContainer = tw.div``;*/
const CertifiedContainer = styled.ul`
  ${tw`flex items-center justify-start`}
  li {
    ${tw`w-full mt-4`}
  }
`;
const StartupImg = tw.img`w-1/2 py-4`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default ({
  landingPages = components.landingPages,
  innerPages = components.innerPages,
  certifiedLogo = [
    /*{
        logoSrc: UKASL,
        certifiedName: "ukasl",
      },
      {
        logoSrc: ISO,
        certifiedName: "iso",
      },
      {
        logoSrc: EGAC,
        certifiedName: "egac",
      },
      {
        logoSrc: IAF,
        certifiedName: "iaf",
      },
      {
        logoSrc: RO,
        certifiedName: "ro",
      }*/
    {
      logoSrc: CertificationLogo,
      certifiedName: "certificationlogos",
    },
  ],
}) => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} className="aurum-logo" />
          </LogoContainer>

          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>

          <CompanyDescription>
            <LinkB href={innerPages.policycenter.url}>
              Legal Policy Center
            </LinkB>
            <LinkB href="https://aurumlabs.instatus.com/">Status</LinkB>
            <LinkB href={innerPages.sitemap.url}>Sitemap</LinkB>
          </CompanyDescription>
        </WideColumn>
        <Column>
          <ColumnHeading>Products</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href={`${innerPages.hp.url}#desktops`}>
                Desktops & Workstations
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${innerPages.hp.url}#servers`}>Servers</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${innerPages.hp.url}#printers`}>
                Printers & Photocopiers
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={`${innerPages.hp.url}#switches`}>
                Networking Switches
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.edtechsolutions.url}>Edtech Platform</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Solutions</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#conference-studios">Conference Halls / Studios</Link>
            </LinkListItem>

            <LinkListItem>
              <Link href="#hardwaresol">Hardware Solutions</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#edtech">Edtech Solution</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#itsupport">IT Software Solution</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Company</ColumnHeading>
          <LinkList>
            {/* <LinkListItem>
                <Link href={innerPages.whyaurumlabs.url}>Why Aurum</Link>
                </LinkListItem> */}

            <LinkListItem>
              <Link href={innerPages.contactus.url}>Contact Us</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={innerPages.media.url}>Media</Link>
            </LinkListItem>
          </LinkList>
        </Column>

        <CompanyDescription>
          <ColumnHeading>AL Ingenious Solutions Pvt. Ltd.</ColumnHeading>
          <StartupImg src={StartupLogo} className="" />
          Registration No. DIPP93420 <br />
          <br />
          <ColumnHeading>ISO 9001 and ISO 20000 Certified</ColumnHeading>
          <CertifiedContainer>
            {certifiedLogo.map((item, index) => (
              <li>
                <img src={item.logoSrc} alt={item.certifiedName} />
              </li>
            ))}
          </CertifiedContainer>
        </CompanyDescription>
      </FiveColumns>
    </Container>
  );
};
